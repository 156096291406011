<template>
    <div>
        <template v-if="!isEmpty(header.table)">
            {{ cellLabel }}
        </template>
        <template v-else-if="header.type === 'GeographyPoint'">
            {{ formatCoordinates(item[header.value]) }}
        </template>
        <template v-else-if="isDate(item[header.value])">
            {{ formatIsoAsDate(item[header.value]) }}
        </template>
        <template v-else-if="enumService.isEnum(header.type)">
            {{ stringUtility.camelToTitleCase(item[header.value]) }}
        </template>
        <template v-else-if="typeof item[header.value] === 'boolean'">
            {{ item[header.value] ? "Yes" : "No" }}
        </template>
        <template v-else-if="header.type === 'AddressModel'">
            {{ formatAddress(item[header.value]) }}
        </template>
        <template v-else>
            {{ item[header.value] }}
        </template>
    </div>
</template>

<script>
import enumService from "@/services/enumService";
import dateUtility from "@/services/dateUtility";
import stringUtility from "@/services/stringUtility"

export default {
    props: {
        item: {
            type: Object,
            default: null,
        },
        header: {
            type: Object,
            default: null,
        },
        apiData: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            enumService: enumService,
            cellLabel: null,
            stringUtility: stringUtility
        };
    },
    watch: {
        header: {
            immediate: true,
            async handler() {
                this.setCellLabel();
            }
        },
        apiData: {
            immediate: true,
            async handler() {
                this.setCellLabel();
            }
        }
    },

    mounted() {
        this.$nextTick(function () {
            this.setCellLabel();
        })
    },

    methods: {
        isDate(value) {
            return dateUtility.isDate(value);
        },
        async setCellLabel() {
            if (this.isEmpty(this.header) || this.isEmpty(this.header.table)) {
                return;
            }
            let item = this.apiData[this.header.table]?.items.filter(
                e => e.id === this.item[this.header.value]
            )[0];
            let schema = this.getSchema(this.header.table);
            this.cellLabel = await this.getLabel(item, schema.fields);
        }
    },
};
</script>
